import * as React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const Privacy = () => (
  <Layout>
    <Seo title="Privacy Notice" />
    {/* Content */}

    <div className="container max-w-screen-xl pt-16 sm:pt-32 pb-16 sm:pb-64 sm:text-2xl">
      <h1 className="_h1">Privacy Notice</h1>
        <p className="_p">
            This privacy notice (hereinafter „<strong>notice</strong>“) explains how Levercode OÜ (hereinafter “<strong>we</strong>” or „<strong>us</strong>“) processes personal data of users (hereinafter “<strong>you</strong>” or the “
            <strong>data subject</strong>”) of&nbsp;<strong>demo version of LeverID</strong>&nbsp;available on iOS and Android platforms (hereinafter “<strong>application</strong>”).
        </p>
        <p className="_p">This notice describes the processing of personal data by us as a data controller. This means that we individually or along with others determine the purposes and means of the processing of personal data.</p>
        <p className="_p">
            As stated above, this privacy notice provides information on processing of personal data in regard to the demo application. In order to run the demo, we have prepared test data (or “dummy data”) – this means that you do not have to
            provide us with your name and other personal data to try out the application. In this way, we process your personal data as little as possible. However, processing of certain personal data is unavoidable, otherwise the demo
            application will simply not work properly on your device. The personal data we need to process for that reason is described in Section&nbsp;2&nbsp;below.
        </p>
        <h3 className="_p"><strong>1. </strong><strong>DATA CONTROLLER</strong></h3>
        <p className="_p">Levercode OÜ</p>
        <p className="_p"><strong>Registry code</strong>: 12334812</p>
        <p className="_p"><strong>Address</strong>: Ranna tee 9, Maramaa küla, Tartu vald, 60517 Tartu maakond, Estonia</p>
        <p className="_p"><strong>Email</strong>:&nbsp;<a href="mailto:info@levercode.com">info@levercode.com</a></p>
        <p className="_p"><strong>Contact details of data protection officer</strong>: [gilbert.pardla@levercode.com]</p>
        <h3 className="_p"><strong>2. </strong><strong>THE PURPOSES AND THE LEGAL GROUNDS FOR THE PROCESSING OF PERSONAL DATA</strong></h3>
        <p className="_p">When we process your personal data, we rely on the following legal ground:&nbsp;<strong>processing is necessary for the performance of a contract to which you are the party, or in order to take steps at your request prior to entering into a contract.</strong></p>
        <p className="_p"><strong>Purposes of processing</strong></p>
        <p className="_p">Your personal data is processed for the provision of our services, i.e., to allow you to use the application.</p>
        <p className="_p"><strong>Personal data that we process</strong></p>
        <p className="_p">
            Yourdevice ID, public keys, MAC key, encrypted PINs (authentication and signing); unique ID (LeverID in UUID form) that is strictly bound to the device and remembered in core of the application as application/user
            identifier.
        </p>
        <p className="_p">Sources of collection of personal data: we get the personal data from you when you download, install, and launch the application on your device.</p>
        <h3 className="_p"><strong>3. </strong><strong>CATEGORIES OF RECIPIENTS OF PERSONAL DATA AND TRANSFER OF PERSONAL DATA TO THIRD COUNTRIES</strong></h3>
        <p className="_p">In some cases, we may transfer your personal data to certain recipients who are categorized as follows:</p>
        <p className="_p">3.1. Partners who provide the services to us, and who process the personal data on our behalf (data processors), e.g.:</p>
        <p className="_p">(a) Providers of ICT services (including providers of website and platform hosting services and other providers of cloud services);</p>
        <p className="_p">(b) Various contractors (IT development, support).</p>
        <p className="_p">3.2. Public authorities and supervisory bodies, e.g., court, law enforcement authorities, Data Protection Inspectorate. We transfer your personal data to public authorities and supervisory bodies only if the law requires it.</p>
        <p className="_p">
            Unless necessary for the provision of services, we do not transfer your personal data outside of the European Union (EU) or the European Economic Area (EEA), nor to such third country or international organization, the level of data
            protection of which the European Commission has not considered adequate. If your personal data is transferred outside of the EU or EEA, such transfer of personal data will take place only upon appropriate legal basis, and we will
            take appropriate protective measures.
        </p>
        <p className="_p">You have the right to get additional information about the transfer of your personal data by sending us the relevant request at&nbsp;<a href="mailto:info@levercode.com">info@levercode.com</a>.</p>
        <h3 className="_p"><strong>4. </strong><strong>RETENTION OF PERSONAL DATA</strong></h3>
        <p className="_p">We keep your personal data for the period necessary for the achievement of purposes stated in this notice or until the law requires it.</p>
        <p className="_p">Personal data of registered users will be deleted&nbsp;at the end of every month&nbsp;after completion of each demo.“</p>
        <p className="_p">Specific terms of retention can be exercised by accessing your personal data. Please see the explanation in the section “Your rights regarding the personal data”.</p>
        <h3 className="_p"><strong>5. </strong><strong>YOUR RIGHTS REGARDING THE PERSONAL DATA</strong></h3>
        <p className="_p">
            <strong>Right of access to your data</strong>: you have the right to know, whether personal data concerning you are being processed or not, what is the purpose of processing and what are the categories of personal data. Besides, to
            whom the personal data is disclosed (especially the recipients in third countries), for how long the personal data is retained and what are your rights concerning rectification, erasure and restriction of the processing.
        </p>
        <p className="_p"><strong>Right of rectification</strong>: you have the right to demand rectification of the personal data concerning you if the data are inaccurate or incomplete.</p>
        <p className="_p">
            <strong>Right of erasure</strong>: in some cases, you have the right to demand erasure of the personal data concerning you, for example in case when you withdraw your consent and there are no other legal grounds for the processing
            of the personal data.
        </p>
        <p className="_p"><strong>Right to restrict the processing</strong>: in some cases, you have the right to restrict processing of the personal data concerning you for a certain time (e.g., if you have objected the processing of personal data).</p>
        <p className="_p">
            <strong>Right to object</strong>: you have the right to object the processing of personal data, which is processed based on the legitimate interest, including profiling. Upon objection, we will no longer process the personal data
            unless we demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms.
        </p>
        <p className="_p">
            <strong>Right to data portability</strong>: if processing of your personal data is based on your consent or the contract with us and the data processing is carried out by automated means, then you have the right to receive the
            personal data concerning you, which you have provided to us, in a structured, commonly used and machine-readable format. Also, you have the right to claim to transmit those personal data to another service provider if it is
            technically possible.
        </p>
        <p className="_p">
            <strong>Right to turn to us, supervisory authority, or a court</strong>: if you want to exercise the above-mentioned rights, please send us an email at&nbsp;<a href="mailto:info@levercode.com">info@levercode.com</a>. If you find
            that your rights have been breached, you have the right to turn to the Data Protection Inspectorate (<em>Andmekaitse Inspektsioon</em>) and/or court. The contact details of the Data Protection Inspectorate are available at&nbsp;
            <a href="http://www.aki.ee/">www.aki.ee</a>.
        </p>
        <h3 className="_p"><strong>6. </strong><strong>AMENDMENT OF THIS NOTICE</strong></h3>
        <p className="_p">We have the right to amend this notice unilaterally. We will notify of amendment of this notice in the application, by email or in other manner.</p>
    </div>
  </Layout>
);

export default Privacy;
